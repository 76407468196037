import React, { ComponentProps, useContext, useState } from "react";
import { useRouter } from "next/router";

// Components
import { toast } from "@vahak/core/dist/components/toast/toast";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import StandaloneLoadPostingFormModal from "../../components/posting/load-posting/standalone-load-posting-form-modal/StandaloneLoadPostingFormModal";
import LoadVerificationHeader from "../../components/admin/load/load-verification-header/LoadVerificationHeader";
import UpdateLoadRemarks, { LoadRemarksSubmitParams } from "../../components/admin/load/UpdateLoadRemarks";
import QuickActionPopup, { QuickActionPopupProps } from "../../components/quick-action-popup/QuickActionPopup";

// Hooks
import { useUpdateTicketStatusMutation } from "./useUpdateTicketStatusMutation";
import { UpdateRemarksReferenceType, useUpdateRemarks } from "../remarks/useUpdateRemarks";
import { usePremiumBulkLoadDelete } from "../usePremiumLoadsList";
import { useGetLoadDetailsQueryV2 } from "../load/useGetLoadAllDetailsV2/useGetLoadAllDetailsV2";

// Icons
// Methods
import { arrayAddIf, getJoinedStringFromArray } from "@vahak/core-ui/dist/methods/array";

// Constant
import { ExpectedAmountType } from "@vahak/core/dist/constants/payment";
import { BID_STATUS } from "@vahak/core/dist/constants/bidStatus";
import { LOAD_STATUS } from "@vahak/core/dist/constants/loadStatus";
import { LORRY_CATEGORIES_ENUM, LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import {
    LoadTicketType,
    LoadTicketTypeToNameMap,
    TicketStatusMap,
    TicketSystemDocActionType
} from "../../components/admin/ticket-system-helper/constants";
import {
    LOAD_CREATION_FORM_IDS,
    initialLoadCreationFormValues,
    loadCreationFormFieldsTypes
} from "../../components/posting/helpers/constants";
import { NoSuccessErrorHandler } from "@vahak/core/dist/constants/api";
import { LoadAllDetails } from "../load/useGetLoadAllDetailsV2/type";
import { LOAD_VERIFIED, LOAD_VERIFIED_TAP_N_GO } from "../../components/admin/ticket-system-helper/loadDispositions";
import { copyToClipboard } from "@vahak/core/dist/methods/copyToClipboard";
import { TicketSysIDs } from "../../components/admin/ticket-system-helper/TicketSysIDs";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../context/load-lorry-posting";
import { removeSubstringWithParenthesis } from "../../methods/removeSubstringWithParenthesis";

interface UseLoadVerificationProps {
    loadId?: number;
    ticketId?: number;
    companyId?: number;
    ticketType?: LoadTicketType;
    onSuccess?: () => void;
}

export const handleGetShipperNumber = (phnNumber?: String) => {
    phnNumber && copyToClipboard(String(phnNumber), "Phone No. copied");
};

export const getPrefilledDataFromMarketLoadResponse = (load: LoadAllDetails) => {
    const { load_detail } = load ?? {};
    const preFilledData: Partial<loadCreationFormFieldsTypes> = {
        ...initialLoadCreationFormValues,
        srcText: getJoinedStringFromArray([
            removeSubstringWithParenthesis(load_detail?.source_city_name ?? ""),
            load_detail?.source_state_name
        ]),
        destText: getJoinedStringFromArray([
            removeSubstringWithParenthesis(load_detail?.destination_city_name ?? ""),
            load_detail?.destination_state_name
        ]),
        material: load_detail?.material_type,
        quantity: load_detail?.quantity,
        quantityType: String(load_detail?.lorry_type).includes("Litre(s)") ? "Kilo Litre(s)" : "Tonne(s)",
        expectedAmount: Number(load_detail?.expected_amount ?? 0),
        isNegotiable: false,
        paymentType: load_detail?.payment_type as 0,
        isFixedPrice: !load_detail?.expected_type,
        advanceAmount: load_detail?.advance_payment_percentage,
        remarks: load_detail?.remarks,
        is_odc: load_detail?.is_odc,
        length: load_detail?.length,
        breadth: load_detail?.breadth,
        height: load_detail?.height,
        lorryType: load_detail?.lorry_type_id,
        isLorryCapacityRequired: false,
        lorryBodyType: load_detail?.body_type,
        lorrySize: load_detail?.size,
        sizeText: load_detail?.size_text,
        lorryTyres: load_detail?.total_tyres,
        loadingTimestamp: load_detail?.loading_timestamp,
        postedTime: load_detail?.posted_time
    };

    return preFilledData;
};

export type LoadVerificationQuickAction = "version" | "report-time" | "missing-info";

export const useLoadVerification = ({
    companyId,
    loadId,
    ticketId,
    onSuccess,
    ticketType
}: UseLoadVerificationProps) => {
    const { mutateAsync: updateTicketStatus } = useUpdateTicketStatusMutation();
    const { updateLoadLorryPostingData, openLoadPostingModal } = useLoadLorryPosting();

    const [actionType, setActionType] = useState<TicketSystemDocActionType>();
    const [quickActionType, setQuickActionType] = useState<LoadVerificationQuickAction>();

    const closeActionConfirmationModal = () => {
        setActionType(undefined);
    };

    const closeQuickActionConfirmationModal = () => {
        setQuickActionType(undefined);
    };

    const {
        data: loadInfo,
        refetch: refetchLoadData,
        isFetching
    } = useGetLoadDetailsQueryV2(
        {
            loadId: loadId!,
            companyId: companyId,
            preload: [
                "agent_details",
                "company_detail",
                "bid_infos",
                ...arrayAddIf(ticketType === LoadTicketType.POST_PLACEMENT, [
                    "bid_infos.bidder_info",
                    "bid_infos.tenderer_info",
                    "bid_infos.payment_details"
                ])
            ]
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!loadId,
            ...NoSuccessErrorHandler
        }
    );

    const { agent_detail, bid_infos, bids_summary: { received_bids = 0, sent_bids = 0 } = {} } = loadInfo ?? {};

    const { paidAmount = 0, totalAmount = 0 } =
        bid_infos
            ?.filter(({ status }) => [BID_STATUS.IN_TRANSIT, BID_STATUS.COMPLETED].includes(status!))
            ?.reduce(
                (prev, current) => {
                    prev.totalAmount += (current?.cross_bid ? current?.cross_bid : current?.amount) || 0;
                    prev.paidAmount += current?.payment_details?.released_payment_amount ?? 0;
                    return prev;
                },
                {
                    totalAmount: 0,
                    paidAmount: 0
                }
            ) ?? {};

    const {
        is_verified,
        status,
        lorry_type_id,
        expected_amount = 0,
        material_type = "",
        quantity = 0,
        advance_payment_percentage,
        expected_type, // 0 is Fixed 1 - tonne
        payment_type, // 0 - adv  1 - to pay
        posted_time = "",
        loading_timestamp = 0,
        destination: destination_place = "",
        source: source_place = "",
        company_id,
        remarks = "",
        calling_disposition
    } = loadInfo?.load_detail ?? {};

    const inTransitBid = bid_infos?.find((bid) => bid.status === BID_STATUS.IN_TRANSIT);
    const transporterInfo =
        company_id === inTransitBid?.tenderer_id ? inTransitBid?.bidder_info : inTransitBid?.tenderer_info;

    const onSuccessEditLoad = () => {
        refetchLoadData();
    };

    const handlePostRemarksUpdate = (msg = "", error = "") => {
        msg && toast.success(msg);
        error && toast.error(error);
        closeActionConfirmationModal();
        refetchLoadData();
        onSuccess?.();
    };

    const { mutateAsync: updateRemarks } = useUpdateRemarks();
    const { mutateAsync: deleteBulkLoadMutate } = usePremiumBulkLoadDelete();

    const updateLoadRemarks = async (id: number, params: LoadRemarksSubmitParams) => {
        if (id) {
            await updateRemarks({
                root_reference_type: UpdateRemarksReferenceType.LOAD,
                root_reference_id: id,
                reference_type: UpdateRemarksReferenceType.LOAD,
                reference_id: id,
                disposition: params.disposition,
                sub_disposition: params.subDisposition,
                other: params.comment,
                source: ticketType ? LoadTicketTypeToNameMap[ticketType] : "-",
                ticket_type: LoadTicketType.VERIFICATION
            });
        }
    };

    const markLoadExpire = async (id: number) => {
        if (id) {
            await deleteBulkLoadMutate({
                id: [id]
            });
        }
    };

    const handleLoadAccept = (params: LoadRemarksSubmitParams) => {
        if (ticketId && loadId) {
            const isLoadVerificationScreen = !!ticketType && [LoadTicketType.VERIFICATION].includes(ticketType);
            const isVerifiedLoad = params.subDisposition === LOAD_VERIFIED;
            const isTapNgoLoad = params.subDisposition === LOAD_VERIFIED_TAP_N_GO;
            const isVerifiedTkt = isVerifiedLoad || isTapNgoLoad;

            if (isTapNgoLoad) {
                if (!loading_timestamp) {
                    closeActionConfirmationModal();
                    return setQuickActionType("report-time");
                }
                // if (compareVersionStrings(loadInfo?.company_detail?.user_app_version ?? "", TAP_N_GO_APP_VERSION) < 0) {
                //     closeActionConfirmationModal();
                //     return setQuickActionType("version");
                // }
                if (!expected_amount) {
                    closeActionConfirmationModal();
                    return setQuickActionType("missing-info");
                }
            }

            let tktStatus = TicketStatusMap.Verified;
            if (isLoadVerificationScreen) {
                tktStatus = isVerifiedTkt ? TicketStatusMap.Verified : TicketStatusMap.Rejected;
            }

            updateTicketStatus(
                {
                    ticket_id: ticketId,
                    status: tktStatus,
                    ...(isLoadVerificationScreen &&
                        isVerifiedTkt && {
                            ticket_information: {
                                id: loadId,
                                is_verified: isVerifiedTkt,
                                ...(isTapNgoLoad && {
                                    is_tap_n_go: isTapNgoLoad
                                }),
                                ...(isVerifiedTkt && {
                                    verification_source: "cs"
                                })
                            }
                        })
                },
                {
                    onSuccess: async () => {
                        await updateLoadRemarks(loadId, params);
                        handlePostRemarksUpdate(isVerifiedTkt ? "Load Verified!" : "Load Remarks Updated!");
                    },
                    onError: (err) => {
                        err?.message && toast.error(err?.message);
                        closeActionConfirmationModal();
                    }
                }
            );
        }
    };

    const handleLoadRejection = (params: LoadRemarksSubmitParams) => {
        if (ticketId && loadId) {
            updateTicketStatus(
                {
                    ticket_id: ticketId,
                    status: TicketStatusMap.Rejected
                },
                {
                    onSuccess: async () => {
                        await updateLoadRemarks(loadId, params);
                        if (
                            !!ticketType &&
                            [LoadTicketType.VERIFICATION, LoadTicketType.FULFILLMENT].includes(ticketType) &&
                            status === LOAD_STATUS.ACTIVE
                        ) {
                            await markLoadExpire(loadId);
                        }
                        handlePostRemarksUpdate("Load has been rejected!");
                    },
                    onError: closeActionConfirmationModal
                }
            );
        }
    };

    const onClickEditLoad = () => {
        if (loadId) {
            updateLoadLorryPostingData?.({
                category: "edit",
                type: "load",
                data: {
                    values: getPrefilledDataFromMarketLoadResponse(loadInfo!) as loadCreationFormFieldsTypes,
                    id: loadId
                },
                onSuccess: () => onSuccessEditLoad(),
                originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.TICKET_SYSTEM,
                isModalOpen: true,
                skipSuccessScreen: true
            });
            openLoadPostingModal?.(LOAD_CREATION_FORM_IDS.LOAD_INFO);
        }
    };

    const getActionModalTitleAndBody = () => {
        if (!ticketType) {
            return {};
        }

        switch (actionType) {
            case TicketSystemDocActionType.ACCEPT: {
                return {
                    Title: "Verify Load",
                    Body: <UpdateLoadRemarks onSubmit={handleLoadAccept} ticketType={ticketType!} />
                };
            }

            case TicketSystemDocActionType.REJECT: {
                return {
                    Title: "Reject/Expire Load",
                    Body: <UpdateLoadRemarks onSubmit={handleLoadRejection} ticketType={ticketType!} />
                };
            }

            default:
                return {};
        }
    };

    const { Body, Title = "" } = getActionModalTitleAndBody();

    const getQuickActionModalProps = (): QuickActionPopupProps => {
        const actionWrapper = (fn: Function, ...params: any[]) => {
            return () => {
                closeQuickActionConfirmationModal();
                fn?.(...params);
            };
        };
        const common = {
            ctaAction: actionWrapper(handleGetShipperNumber, loadInfo?.company_detail?.phone_number),
            ctaText: "Get Shipper's Phone Number"
        };
        switch (quickActionType) {
            case "report-time": {
                return {
                    ...common,
                    header: "The user's reported time appears to be incorrect",
                    secondaryCtaAction: actionWrapper(onClickEditLoad),
                    secondaryCtaText: "Add reporting time",
                    secondaryCtaProps: {
                        id: TicketSysIDs.loadTicket.editReportingTime
                    }
                };
            }
            case "version": {
                return {
                    ...common,
                    header: `The user's Tap 'n' Go version, which is currently ${loadInfo?.company_detail?.user_app_version}, requires an update to the latest version.`,
                    secondaryCtaProps: {
                        id: TicketSysIDs.loadTicket.appVersionIssue
                    }
                };
            }
            case "missing-info": {
                return {
                    ...common,
                    header: "The user did not provide information for one of the required fields.",
                    secondaryCtaAction: actionWrapper(onClickEditLoad),
                    secondaryCtaText: "Add missing fields",
                    secondaryCtaProps: {
                        id: TicketSysIDs.loadTicket.editMissingField
                    }
                };
            }

            default:
                return {};
        }
    };
    const quickActionProps = getQuickActionModalProps();
    const LoadVerificationModalComponent = (
        <>
            <ModalOrBottomSheetWrapper titleText={Title} isOpen={!!actionType} onToggle={closeActionConfirmationModal}>
                {Body}
            </ModalOrBottomSheetWrapper>

            <QuickActionPopup
                isOpen={!!quickActionType}
                onToggle={closeQuickActionConfirmationModal}
                {...quickActionProps}
            />
        </>
    );

    const canAcceptLoad = [LOAD_STATUS.ACTIVE, LOAD_STATUS.IN_TRANSIT].includes(loadInfo?.load_detail?.status!);
    const canEditLoad = [LOAD_STATUS.ACTIVE].includes(loadInfo?.load_detail?.status!);
    const canDisableLoad =
        !!ticketType &&
        [LoadTicketType.VERIFICATION, LoadTicketType.FULFILLMENT, LoadTicketType.POST_PLACEMENT].includes(ticketType);

    return {
        isFetching,
        isLoadDetailsAvailable: !!loadInfo,
        LoadVerificationModalComponent,
        callingDisposition: calling_disposition,
        onClickEditLoad: canEditLoad ? onClickEditLoad : undefined,
        onClickRejectLoad: canDisableLoad
            ? () => {
                  setActionType(TicketSystemDocActionType.REJECT);
              }
            : undefined,
        onClickAcceptLoad: canAcceptLoad
            ? () => {
                  setActionType(TicketSystemDocActionType.ACCEPT);
              }
            : undefined,
        loadInfo: {
            lorryType: LORRY_TYPES.find((lorry) => lorry.id === lorry_type_id)?.label ?? "",
            qty: quantity,
            paymentType: payment_type!,
            materialType: material_type,
            loadId: loadId!,
            expectedAmtType: expected_type!,
            status: status!,
            sentBidsCount: sent_bids,
            receivedBidsCount: received_bids,
            source: source_place ?? "",
            destination: destination_place ?? "",
            remarks: remarks,
            isVerified: !!is_verified,
            amount: expected_type === ExpectedAmountType.FIXED_PRICE ? expected_amount : expected_amount * quantity,
            perUnitAmount:
                expected_type === ExpectedAmountType.FIXED_PRICE
                    ? Math.ceil(expected_amount / quantity)
                    : expected_amount,
            quantityType: lorry_type_id === LORRY_CATEGORIES_ENUM.TANKER ? "Kilo Litre" : "Tonne",
            advancePercentage: advance_payment_percentage,
            postedAt: posted_time,
            loadingTimestamp: loading_timestamp,
            appVersion: loadInfo?.company_detail?.user_app_version,
            loadSourceCityId: loadInfo?.load_detail?.source_city_id,
            isSpot: loadInfo?.load_detail?.is_spot_load,
            isTapNgo: loadInfo?.load_detail?.is_tap_n_go,
            bodyType: loadInfo?.load_detail?.body_type,
            size: loadInfo?.load_detail?.size,
            sizeText: loadInfo?.load_detail?.size_text,
            lorryTypeId: loadInfo?.load_detail?.lorry_type_id,
            totalTyre: loadInfo?.load_detail?.total_tyres,
            additionalCharges: loadInfo?.load_detail?.meta_data?.loading_charges_detail?.charges
        } as ComponentProps<typeof LoadVerificationHeader>["loadInfo"],
        shipperDetails: {
            companyName: loadInfo?.company_detail?.name ?? "",
            isAadhaarVerified: !!loadInfo?.company_detail?.is_aadhaar_verified,
            isGSTVerified: !!loadInfo?.company_detail?.is_gst_verified,
            isBankVerified: !!loadInfo?.company_detail?.is_bank_verified,
            isMember: !!loadInfo?.company_detail?.is_member,
            agentDetails: agent_detail,
            phoneNumber: loadInfo?.company_detail?.phone_number ?? "-",
            companyDescription: `Shipper detail`
        },
        paymentInfo: transporterInfo ? { totalAmount, paidAmount, pendingAmount: totalAmount - paidAmount } : undefined,
        transporterDetails: transporterInfo
            ? {
                  companyName: transporterInfo?.name ?? "",
                  phoneNumber: transporterInfo?.phone_number ?? "-",
                  isAadhaarVerified: !!transporterInfo?.is_aadhaar_verified,
                  isGSTVerified: !!transporterInfo?.is_gst_verified,
                  isBankVerified: !!transporterInfo?.is_payment_linked,
                  isMember: !!transporterInfo?.is_member,
                  agentDetails: transporterInfo?.agent_details,
                  companyDescription: ""
              }
            : undefined
    };
};
